<template>
  <section class="modalRequirements" v-if="$route.query.showModal === 'true'">
    <div class="modalRequirements__wrapper">
      <div class="modalRequirements__container fade-in">
        <div class="modalRequirements__actions">
          <h1 class="">{{ requirements ? "Editar " : "Crear" }} Requerimiento</h1>
          <button class="modalRequirements__actions-button" @click="resetData()">
            <iconic name="close"></iconic>
          </button>
        </div>
        <div class="modalRequirements__line"></div>
        <div class="modalRequirements__toggle">
          <label for="toogle">Estado:</label>
          <ToggleInputVue id="toogle" :checked="isEnable" @isChecked="isEnable = $event"></ToggleInputVue>
        </div>
        <div class="modalRequirements__data">
          <div class="modalRequirements__input">
            <label for="input">Nombre:</label>
            <input ginput class="modalRequirements__input-input" v-model="name" placeholder="Descripción" id="input" />
          </div>
          <div class="modalRequirements__input">
            <label for="input">Descripción:</label>
            <input ginput class="modalRequirements__input-input" v-model="description" placeholder="Descripción" id="input" />
          </div>
          <div class="modalRequirements__input">
            <label for="Area">Area *:</label>
            <select id="Area" :disabled="requirements.name === 'otro'" ginput class="modalRequirements__input-input" v-model="area" @change="otro = area.name">
              <option value="">Seleccione un area</option>
              <option :value="area._id" v-for="(area, index) in areas" :key="index">{{ area.name }}</option>
            </select>
          </div>
          <div class="modalRequirements__input">
            <label for="input">Prioridad:</label>
            <select ginput class="modalRequirements__input-input" v-model="priority">
              <option selected value="">Seleccione una prioridad</option>
              <option :value="data[priori]" v-for="(priori, idx) in prioritys" :key="idx">
                {{ priori }}
              </option>
            </select>
          </div>
          <div class="modalRequirements__input">
            <label for="input">Posición:</label>
            <input ginput type="number" class="modalRequirements__input-input" v-model="index" placeholder="indica la posición" id="input" />
          </div>
          <div class="modalRequirements__input">
            <label for="input">Roles:</label>
            <div class="modalRequirements__input-checkBox">
              <label class="modalRequirements__input-label" for="input">Monitor</label>
              <input type="checkbox" class="modalRequirements__input-check mycheck" v-model="roles.monitor" id="input" />
              <label class="modalRequirements__input-label" for="input">Modelo</label>
              <input type="checkbox" class="modalRequirements__input-check mycheck" v-model="roles.model" id="input" />
              <label class="modalRequirements__input-label" for="input">Coordinador</label>
              <input type="checkbox" class="modalRequirements__input-check mycheck" v-model="roles.coordinator" id="input" />
              <label class="modalRequirements__input-label" for="input">Superadmin</label>
              <input type="checkbox" class="modalRequirements__input-check mycheck" v-model="roles.superadmin" id="input" />
              <label class="modalRequirements__input-label" for="input">Empleado</label>
              <input type="checkbox" class="modalRequirements__input-check mycheck" v-model="roles.employee" id="input" />
            </div>
            <div class="" @click="editarDireccion = true" v-if="createRoleArray().length">
              <b>Editar dirección</b>
            </div>
            <div class="modalRequirements__directedTos" v-if="editarDireccion && createRoleArray().length">
              <label for="input">Dirigido a:</label>
              <div class="modalRequirements__directedTo">
                <select id="Area" ginput class="modalRequirements__input-input input" v-model="roleTo" @change="otro = area.name">
                  <option value="">Seleccione un rol</option>
                  <option :value="index" v-for="(area, index) in $global.dictionary.roles_es_auto" :key="index">{{ area }}</option>
                </select>
                <select id="Area" ginput class="modalRequirements__input-input input" v-model="areaTo" @change="otro = area.name">
                  <option value="">Seleccione un area</option>
                  <option :value="area._id" v-for="(area, index) in areas" :key="index">{{ area.name }}</option>
                </select>
                <button class="helpdesks__container-plus" @click="addDirect" :disabled="!roleTo && !areaTo">+</button>
              </div>
              <div>
                <div class="modalRequirements__directedTos-list" v-for="(data, idx) in ditectedTo" :key="idx">
                  <strong class="modalRequirements__directedTos-text">
                    Rol:
                    {{ idx }}
                    Area:
                    {{ data.name }}
                  </strong>
                  <button class="modalRequirements__directedTos-listsButton" @click="removeDirect(idx)">
                    <iconic name="trash"></iconic>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="modalRequirements__sendOrCancel">
            <button class="modalRequirements__sendOrCancel-button modalRequirements__sendOrCancel-button-cancel" @click="resetData()">
              Cancelar
            </button>
            <button class="modalRequirements__sendOrCancel-button modalRequirements__sendOrCancel-button-save" :disabled="!validInformation" @click="sendComplete()">
              {{ requirements ? "Guardar" : "Crear" }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import ModalVue from "../modals/Modal.vue";
  import ToggleInputVue from "../input/ToggleInput.vue";
  import mixinCrud from "./mixinCrud";
  export default {
    components: { ModalVue, ToggleInputVue },
    props: ["requirements", "closeModal", "sendData", "areas"],
    mixins: [mixinCrud],
    data() {
      return {
        roles: {
          monitor: false,
          model: false,
          coordinator: false,
          superadmin: false,
          employee: false,
        },
        area: "",
        name: "",
        priority: "",
        timeForresponse: "",
        description: "",
        index: 0,
        isEnable: true,
        prioritys: {
          low: "Baja",
          medium: "Media",
          urgent: "Alta",
        },
        data: { Baja: "low", Media: "medium", Alta: "urgent" },
        propReactive: this.requirements,
        ditectedTo: {},
        editarDireccion: false,
        roleTo: "",
        areaTo: "",
      };
    },
    watch: {
      $route() {
        if (this.requirements) this.initModal();
      },
    },
    computed: {
      validInformation() {
        if (this.name && this.area && this.priority && this.description && this.createRoleArray().length) {
          return true;
        }
        return false;
      },
    },
    methods: {
      addDirect() {
        if (this.roles[this.roleTo] && !this.ditectedTo[this.roleTo] && this.roleTo && this.areaTo) {
          this.ditectedTo[this.roleTo] = { _id: this.areaTo, name: this.validArea(this.areaTo) };
        }
        this.roleTo = "";
        this.areaTo = "";
      },
      removeDirect(role) {
        this.ditectedTo = Object.keys(this.ditectedTo).reduce((acc, key) => {
          if (key !== role) acc[key] = this.ditectedTo[key];
          return acc;
        }, {});

        this.roleTo = "";
        this.areaTo = "";
      },
      resetData() {
        this.roles = {};
        this.area = "";
        this.name = "";
        this.priority = "";
        this.timeForresponse = "";
        this.description = "";
        this.index = 0;
        this.isEnable = true;
        this.propReactive = null;
        this.ditectedTo = {};
        this.setFilter("reset");
        if (this.closeModal) this.closeModal();
      },
      async sendComplete() {
        if (!this.validInformation) return;
        const body = {
          roles: this.createRoleArray(),
          idArea: this.area,
          name: this.name,
          priority: this.priority,
          timeForresponse: this.timeForresponse || "",
          description: this.description,
          index: this.index,
          isEnable: this.isEnable || false,
          directTo: this.ditectedTo,
        };
        if (this.requirements) {
          body._id = this.requirements._id;
          await this.sendData("update", body, this.requirements);
          this.resetData();
          return;
        }
        await this.sendData("create", body);
        this.resetData();
      },
      validRoles() {
        this.requirements?.roles?.forEach((role) => {
          if (role === "monitor") this.roles.monitor = true;
          if (role === "model") this.roles.model = true;
          if (role === "coordinator") this.roles.coordinator = true;
          if (role === "superadmin") this.roles.superadmin = true;
          if (role === "employee") this.roles.employee = true;
        });
      },
      createRoleArray() {
        const roles = [];
        if (this.roles.monitor) roles.push("monitor");
        if (this.roles.model) roles.push("model");
        if (this.roles.coordinator) roles.push("coordinator");
        if (this.roles.superadmin) roles.push("superadmin");
        if (this.roles.employee) roles.push("employee");
        return roles;
      },
      direcTovalue() {},

      initModal() {
        this.area = this.requirements?.idArea;
        this.name = this.requirements?.name;
        this.priority = this.requirements?.priority;
        this.timeForresponse = this.requirements?.timeForresponse;
        this.description = this.requirements?.description;
        this.index = this.requirements?.index;
        this.isEnable = this.requirements?.isEnable;
        this.ditectedTo = this.requirements?.directTo || {};
        this.validRoles();
      },
    },
  };
</script>

<style lang="scss">
  .modalRequirements {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    padding: 22px;
    background-color: rgba(0, 0, 0, 0.144);
    z-index: 1000;
    &__wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      position: relative;
    }
    &__container {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 1440px;
      height: auto;
      max-height: 80vh;
      background-color: #ffffff;
      overflow-y: auto;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 12px;
    }
    &__actions,
    &__toggle {
      padding: 10px 20px;
    }
    &__data {
      padding: 0 20px 20px 20px;
    }
    &__actions {
      width: 100%;
      display: flex;
      margin: 0 0 10px 0;
      justify-content: space-between;
      &-button {
        border: none;
        background-color: transparent;
        color: $primary-color;
      }
    }
    &__line {
      border-bottom: 1px solid rgba(171, 171, 171, 0.602);
      width: 120%;
    }
    &__directedTo {
      display: flex;
      justify-content: space-between;
      margin: 2px 0;
    }
    &__directedTos {
      display: flex;
      flex-direction: column;
      text-transform: capitalize;
      margin: 2px 0;
      max-height: 120px;
      overflow: auto;
      &-list {
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
        border: 1px solid rgba(171, 171, 171, 0.602);
        padding: 5px;
        border-radius: 5px;
      }
      &-listsButton {
        padding: 5px 0 0 0;
        border: none;
        background: none;
        color: $primary-color;
      }
    }
    &__sendOrCancel {
      display: flex;
      justify-content: center;
      margin: 20px 0 0 15px;
      &-button {
        margin: 0 10px;
        border-radius: 5px;
        padding: 5px;
        width: 100px;
        font-weight: bold;
        &-save {
          background-color: $primary-color;
          color: white;
          border: 1px solid $primary-color;
          transition: all 0.3s ease-in-out;
          &:hover {
            background-color: #d41b1b;
            transition: all 0.3s ease-in;
            color: white;
          }
        }
        &-cancel {
          background-color: white;
          border: 1px solid $primary-color;
          color: $primary-color;
          transition: all 0.3s ease-in;
          &:hover {
            background-color: $primary-color;
            color: white;
            transition: all 0.3s ease-in-out;
          }
        }
      }
    }
    &__input {
      padding: 5px 0;
      width: 100%;
      .input {
        max-width: 255px;
      }
      &-input {
        width: 100%;
        border: 1px solid #d8d8d8;
      }

      &-checkBox {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
      }
      &-check {
        border: 1px solid #d8d8d8;
        margin-bottom: 5px;
      }
    }
    .toggle-switch {
      margin-left: 10px;
      width: 40px;
      height: 22px;
      &::before {
        box-shadow: none;
        border: none;
        width: 16px;
        height: 16px;
        left: 3px;
        top: 3px;
      }
    }
    .toggle-checkbox:checked + .toggle-switch {
      &:before {
        left: 20px;
        box-shadow: none;
      }
    }
    [disabled="true"] {
      pointer-events: none;
    }
  }
</style>
